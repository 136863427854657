.button {
    display: flex;
    /* width: 100px; */
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    box-sizing: border-box;


    color: #FBFBFB;
    font-size: 14px;
    /* font-family: Normalidad Medium Var; */
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
}


@media (max-width: 600px) {
    .button {
    width: 100% !important;
    margin-inline-start: 0 !important;
    }
}
