.orderTimeline {
    display: flex;
    flex-direction: column;
    width: 54%;
    min-height: 654px;
    flex-shrink: 0;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);

    padding-inline: 49px;
    padding-top: 42px;
    padding-bottom: 42px;

    margin-bottom: 32px;
    box-sizing: border-box;

}

.titleDiv {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 54px;

}

.title {
    color: #000;
    font-size: 24px;
    /* font-family: Normalidad Medium Var; */
    font-style: normal;
    font-weight: 500;
    /* line-height: 16px; */
}

.line{
    display: flex;
    margin-inline-start: 9px;
}


@media (max-width: 992px) {
    .orderTimeline {
        
        width: 100%;
        
        padding-inline: 16px;
        box-sizing: border-box;
        padding-top: 34px;
    }


}