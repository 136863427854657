.navbar {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 32px;
    justify-content: space-between;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
}

.logoEn {
    margin-left: 41px;
}

.logoAr {
    margin-right: 41px;
}

.languageEn {
    margin-right: 41px;
    color: #1D1D1D;
    font-size: 16px;
    /* font-family: Helvetica Neue LT Arabic; */
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
}

.languageAr {
    margin-left: 41px;
    color: #1D1D1D;
    font-size: 16px;
    /* font-family: Normalidad Regular Var; */
    font-style: normal;
    font-weight: 507;
    line-height: 16px;
    cursor: pointer;
}