.orderStatusPin {
    display: flex;
    min-height: 28px;
    padding: 4px 12px;
    align-items: center;
    gap: 8px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #FFD9C2;
    background: rgba(255, 129, 54, 0.03);


    color: #1D1D1D;
    font-size: 14px;
    /* font-family: Normalidad Regular Var; */
    font-style: normal;
    font-weight: 507;
    line-height: 20px;
    /* flex-wrap: wrap; */
}




@media (max-width: 600px) {
    .orderStatusPin {
        max-width: 148px;
    }
}